export const MEMBER_APPLE_APP_STORE_URL =
  'https://apps.apple.com/us/app/shipt-same-day-delivery/id971888874?mt=8'
export const MEMBER_GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=com.shipt.groceries'
export const SHOPPER_APPLE_APP_STORE_URL =
  'https://apps.apple.com/us/app/shipt-shopper-shop-for-pay/id976353472'
export const SHOPPER_GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=com.shipt.shopper&hl=en_US'
export const CIRCLE_MASTERCARD_APPLICATION_URL =
  'https://www.target.com/redcard/credit-card-application'
