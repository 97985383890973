import {
  FacebookIcon,
  InstagramIcon,
  PinterestIcon,
  SocialTwitterIcon,
} from '@shipt/design-system-icons'

export const getFooterIcon = (linkName: string) => {
  switch (linkName) {
    case 'facebook':
      return <FacebookIcon />
    case 'instagram':
      return <InstagramIcon />
    case 'pinterest':
      return <PinterestIcon />
    case 'twitter':
      return <SocialTwitterIcon />
    default:
      return null
  }
}
